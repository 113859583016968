import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../../api'

const baseURL = `${baseAPI}`

/**
 * GET values
 */

export const list = ({ body }: ListMinCostsRequest) =>
  axios.post(`${baseURL}/campaign/min-costs`, body)

export default {
  list
}
