// Axios GLOBAL configuration
import axios, { AxiosStatic, AxiosInstance } from 'axios'

import { getAuthToken, history, removeAuthToken } from '../helpers'

const applyInterceptors = (instance: AxiosStatic | AxiosInstance) => {
  // Request interceptor
  instance.interceptors.request.use(
    config => {
      const language = localStorage.getItem('i18nextLng')
      if (language) {
        config.headers['Accept-Language'] = language
      }

      const token = getAuthToken()
      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    error => Promise.reject(error)
  )

  // Response interceptor
  instance.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 401) {
        removeAuthToken()
        history.push('/login')
      }
      return Promise.reject(error)
    }
  )
}

// Global Axios instance
applyInterceptors(axios)
axios.defaults.baseURL = process.env.REACT_APP_HOST

// bffRetailMedia Axios instance with the same interceptors
export const bffRetailMedia = axios.create({
  baseURL: `${process.env.BFF_RETAIL_MEDIA}`
})

applyInterceptors(bffRetailMedia)
