import { useCallback, useMemo } from 'react'

import SelectSearchable from '~/components/SelectSearchable'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { useAdTypes } from '~/modules/retailMedia/hooks/useAdTypes'

const queryKey = 'ad_type'

const FilterAdtype: React.FC = () => {
  const { t } = useAPPTranslation()

  const { data, isPending, error } = useAdTypes()

  const adTypes: CampaignAdType[] = data.length
    ? data
    : ['product', 'banner', 'sponsored_brand', 'digital_signage']

  const options = adTypes.map(type => ({
    label: t(`rm:adTypes.${type}`, { defaultValue: type }),
    value: type
  }))

  /**
   * <Handle change>
   */
  const queryParams = useQueryParams()
  const selectedValue = queryParams.get(queryKey) as PublisherTagId

  const selectedTag = useMemo(
    () => options.find(({ value }) => value === selectedValue) || null,
    [options, selectedValue]
  )

  const handleChange = useCallback(
    (option: Option) => {
      const value = option?.value

      if (!value) queryParams.delete(queryKey)

      if (value) queryParams.set(queryKey, value)

      history.replace({ search: queryParams.toString() })
    },
    [queryParams]
  )

  return (
    <SelectSearchable
      key={selectedTag?.value}
      name={`${queryKey}-select`}
      placeholder={t('rm:filterByAdType')}
      options={options}
      onChange={handleChange}
      isClearable
      defaultValue={selectedTag}
      isSearchable
      noOptionsMessage={() => t('c:messages.noResultsFound')}
      size="small"
      // dynamic
      error={error}
      loading={isPending}
      loadingMessage={() => `${t('c:words.loading')}...`}
    />
  )
}

export default FilterAdtype
