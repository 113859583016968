import { getValidColor, isReadOnly } from '~/helpers'

import { defaultSearchBy } from './defaultValues'

type AppInfoDto = (data: GetAppInfoResponse) => AppInfoDTOData

const appInfoDTO: AppInfoDto = data => ({
  accountId: data?.account_id,

  name: data?.name,
  description: data?.description,
  logo: data?.logo,
  headerLogo: data?.header_logo,
  headerTheme: data?.header_theme || 'light',

  isWhiteLabel: data?.is_white_label,
  logoWhiteLabel: data?.logo_white_label,
  domain: data?.domain,

  favicon: data?.favicon_url,
  ogImage: data?.image_og,
  colors: {
    header: getValidColor(data?.header_color),
    primary: getValidColor(data?.theme.primary_color),
    secondary: getValidColor(data?.theme.secondary_color),
    dark: getValidColor(data?.theme.dark_color)
  },

  callbackLogoutUrl: data?.callback_logout_url || null,

  faqUrl: data?.faq_url || 'https://newtail.freshdesk.com/support/home',
  newTicketURL:
    data?.new_ticket_url || 'https://newtail.freshdesk.com/support/tickets/new',

  timezone: data?.timezone,
  currencyCode: data?.currency_code || 'BRL',

  hasRecharge: data?.has_recharge && !isReadOnly(),
  rechargeMinValue: data?.min_recharge_value,
  rechargeEmail: data?.recharge_email || 'comercial@newtail.com.br',

  externalSigupUrl: data?.external_signup_url || null,

  // Não retorna da API ainda
  searchProducstBy: data?.search_product_by || defaultSearchBy
})

export default appInfoDTO
