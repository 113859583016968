// React import is required because the react-selct is using React 16, which requires it for JSX.
import React, { useMemo } from 'react'
import { FiExternalLink, FiHelpCircle } from 'react-icons/fi'

import { Button, ButtonNavLink } from '~/components/Buttons'
import RangeSlider from '~/components/RangeSlider'
import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'
import { formatMoneyV2, formatNumberWithDots } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { buttons } from './buttons'

import * as S from './styles'

const AudienceCard: React.FC<AudienceCardProps> = ({
  data,
  onSelect,
  selectedAudienceId,
  isSelectedPreview,
  readOnly = false,
  ...rest
}) => {
  const {
    name: title,
    description,
    price: cost,
    estimated_audience: reach,
    reach_range: reachRange
  } = data
  const { t } = useAPPTranslation()

  const isSelected = selectedAudienceId === data.id
  const hasSelected = !!selectedAudienceId

  const currentButton = useMemo(() => {
    if (isSelectedPreview) {
      return buttons.remove
    }
    if (isSelected) {
      return buttons.selected
    }

    if (hasSelected) {
      return buttons.changeMode
    }

    return buttons.default
  }, [isSelectedPreview, isSelected, hasSelected])

  const handleChange = () => (onSelect ? onSelect?.(data) : null)

  return (
    <S.CardContainer
      className="d-flex flex-column gap-2 p-3 rounded-lg"
      data-selected={isSelected && !isSelectedPreview}
      data-preview={isSelectedPreview}
      {...rest}
    >
      <header className="d-flex justify-content-between gap-2">
        <section className="d-inline-flex align-items-start flex-column info">
          {isSelectedPreview && (
            <Badge text={t('rm:campaign.audience.selected')} size="tiny" />
          )}
          <strong className="text-neutral-low-dark fs-5">{title}</strong>
          {description && (
            <span className="fs-6 text-neutral-low-medium lh-sm">
              {description}
            </span>
          )}
        </section>

        {!readOnly && (
          <aside className="button__container pt-1">
            <Button {...currentButton} size="small" onClick={handleChange} />

            {isSelected && !isSelectedPreview && (
              <Button {...buttons.remove} size="small" onClick={handleChange} />
            )}
          </aside>
        )}
      </header>

      <section className="d-flex align-items-center gap-1">
        <div className="d-flex gap-1">
          <span role="strong" className="reach-title fw-semibold fs-6">
            {t('rm:AudienceCard.text.audienceCost')}:
          </span>
          <span className="fs-6">{formatMoneyV2({ value: cost })}</span>
        </div>
        <div className="d-flex align-items-center ">
          <Badge
            size="small"
            template={cost ? 'warning' : 'success'}
            text={
              cost
                ? t('rm:AudienceCard.text.audienceCost.fixedCost')
                : t('rm:AudienceCard.text.audienceCost.noCost')
            }
          />
          <Tooltip
            icon={<FiHelpCircle />}
            text={t('rm:AudienceCard.text.audienceCostDescription')}
          />
        </div>
      </section>

      {(reach || reachRange) && (
        <section className="reach-row d-flex align-items-center gap-4">
          {reach && (
            <article className="reach-text">
              <strong className="fw-semibold fs-6">
                {t('rm:AudienceCard.text.potentialReach')}:{' '}
              </strong>
              <span className="value d-inline-flex align-items-center fs-6">
                ~ {formatNumberWithDots(reach)} {t('c:words.person_plural')}
                <Tooltip
                  icon={<FiHelpCircle />}
                  text={t('rm:AudienceCard.text.potentialReachDescription')}
                />
              </span>
            </article>
          )}

          {reachRange && (
            <RangeSlider
              min={0}
              max={100}
              value={reachRange}
              readOnly
              minLabel={t('rm:AudienceCard.text.potentialReach.slider.min')}
              maxLabel={t('rm:AudienceCard.text.potentialReach.slider.max')}
              size="small"
            />
          )}
        </section>
      )}

      <footer className="mt-1">
        <ButtonNavLink
          to="/audience-rules"
          className="text-primary fs-6 fw-bold text-decoration-none p-0"
          target="_blank"
          iconRight={<FiExternalLink />}
          text={t('rm:AudienceCard.text.audienceRules.link')}
          template="transparentPrimary"
        />
      </footer>
    </S.CardContainer>
  )
}

export default AudienceCard
