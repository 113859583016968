import { useQuery } from '@tanstack/react-query'

import { listPublisherTagService } from '../services/publisher-tag'

export const usePublisherTags = ({ label }: { label?: string } = {}) => {
  const queryKey = ['get-publisher-tags', label]

  const { isPending, isFetching, data, error } = useQuery({
    queryKey,
    queryFn: async () => {
      const params: ListPublisherTagRequest = { quantity: 100, count: false }

      if (label) params.label = label

      const response = await listPublisherTagService(params)

      if (Array.isArray(response?.data)) {
        const data = response.data as PublisherTag[]

        return {
          data: data
        }
      } else {
        return response
      }
    },
    placeholderData: previousData => previousData
  })

  return { isPending, isFetching, data, error }
}
