import i18n from '~/i18n'

export const targetingTypeOptions: Option<RetailMediaCampaignSegmentation>[] = [
  {
    label: i18n.t('c:capitalize', {
      value: i18n.t('rm:keyword')
    }),
    value: 'keyword'
  },
  {
    label: i18n.t('c:capitalize', {
      value: i18n.t('rm:category')
    }),
    value: 'category'
  }
]
