import React from 'react'
import {
  FaLaptop,
  FaWhatsapp,
  FaHeart,
  FaStore,
  FaShoppingBasket
} from 'react-icons/fa'
import { FiPhone } from 'react-icons/fi'
import { IoBusinessOutline } from 'react-icons/io5'

import lojas_americanas from '~/assets/img/brands/amaricanas-logo.svg'
import amazon from '~/assets/img/brands/amazon-logo.png'
import americanas_delivery from '~/assets/img/brands/americanas_delivery.svg'
import bling from '~/assets/img/brands/bling-logo.png'
import magazine_luiza from '~/assets/img/brands/magalu-logo.svg'
import meli from '~/assets/img/brands/melimetric-logo.svg'
import rappi from '~/assets/img/brands/rappi-logo.svg'
import shopify from '~/assets/img/brands/shopify-logo.png'
import skyhub from '~/assets/img/brands/skyhub-logo.png'
import submarino from '~/assets/img/brands/submarino-logo.png'

const dataLogos = {
  bling,
  skyhub,
  meli,
  submarino,
  magazine_luiza,
  lojas_americanas,
  americanas_delivery,
  amazon,
  rappi,
  shopify
}

export function addLogos(name, width = 80) {
  const normalizeName = name => {
    // Removendo caracter especial
    let str = name.replace(/([^a-z0-9]+)/gi, '_')
    // Transformando em minúscula
    str = str.toLowerCase()
    // Removendo underscore do começo
    if (str.charAt(0) === '_') {
      str = str.substr(1, str.length - 1)
    }
    // Removendo underscore do final
    if (str.charAt(str.length - 1) === '_') {
      str = str.substr(0, str.length - 1)
    }
    // Retornando string tratada
    return str
  }

  const normalizedName = normalizeName(name)

  const lojaFisica = <FaStore className="store" />

  const purchaseForm = <FaShoppingBasket className="purchase" />

  const whatsAppIcon = <FaWhatsapp className="whatsapp" />

  const diaDasMaesIcon = <FaHeart className="dia_das_maes" />

  const ecommerceIcon = <FaLaptop className="ecommerce" />

  const televendasIcon = <FiPhone className="televendas" />

  const b2bIcon = <IoBusinessOutline className="b2b" />

  const withoutName = () => {
    switch (name) {
      case 'loja_fisica':
        return lojaFisica

      case 'lojafisica':
        return lojaFisica

      case 'purchase':
        return purchaseForm

      case 'whatsapp':
        return whatsAppIcon

      case 'whatsapp_bot':
        return whatsAppIcon

      case 'dia_das_maes':
        return diaDasMaesIcon

      case 'magento_ecommerce':
        return ecommerceIcon

      case 'vtex_ecommerce':
        return ecommerceIcon

      case 'vtex':
        return ecommerceIcon

      case 'televendas':
        return televendasIcon

      case 'b2b':
        return b2bIcon

      default:
        return name
    }
  }

  return dataLogos[normalizedName] ? (
    <img
      src={`${dataLogos[normalizedName]}`}
      width={width}
      alt="Logo"
      style={{ objectFit: 'contain', maxHeight: '24px' }}
    />
  ) : (
    withoutName()
  )
}
