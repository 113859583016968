import { useCallback, useMemo } from 'react'

import SelectSearchable from '~/components/SelectSearchable'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { targetingTypeOptions as options } from './options'

const queryKey = 'campaign_targeting'

const FilterTargetingType: React.FC = () => {
  const { t } = useAPPTranslation()
  const placeholder = t('rm:filterByTargetingType')

  /**
   * <Handle change>
   */
  const queryParams = useQueryParams()
  const selectedValue = queryParams.get(queryKey) as PublisherTagId

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === selectedValue) || null,
    [selectedValue]
  )

  const handleChange = useCallback(
    (option: Option) => {
      const value = option?.value

      if (!value) queryParams.delete(queryKey)

      if (value) queryParams.set(queryKey, value)

      history.replace({ search: queryParams.toString() })
    },
    [queryParams]
  )

  return (
    <SelectSearchable
      placeholder={placeholder}
      name={`${queryKey}-select`}
      key={selectedOption?.value}
      options={options}
      onChange={handleChange}
      isClearable
      defaultValue={selectedOption}
      isSearchable
      noOptionsMessage={() => t('c:messages.noResultsFound')}
      size="small"
    />
  )
}

export default FilterTargetingType
