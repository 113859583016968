// Omit<AdResultResponse, 'metrics'>

import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../../../api'

const baseURL = `${baseAPI}/ad/results/v2`

export const listAdsResultsV2Service = ({ params }) =>
  axios.get<AdResultsResponse>(`${baseURL}`, {
    params
  })
