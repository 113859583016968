import { t } from 'i18next'

import { getRetailMediaAccountType } from '~/helpers'

import AdInfo from './elements/AdInfo'
import ReviewActions from './elements/ReviewActions'

const accountType = getRetailMediaAccountType()

export const schema: SchemaMediaReviewTable = () => ({
  /**
   * Dados do anúncio
   */
  title: {
    title: t('rm:adReview.list.title'),
    render: AdInfo,
    showTotal: true
  }
})
