import { t } from 'i18next'

import { getRetailMediaAccountType } from '~/helpers'

import { accountInfo } from '../../_elements/accountInfo'
import AdInfo from './elements/AdInfo'
import BidStrength from './elements/BidStrength'
import { campaignInfo } from './elements/campaign'
import { cost } from './elements/cost'
import Status from './elements/Status'

const accountType = getRetailMediaAccountType()

const toggleValueByStatus = (status, value) => {
  return ['pending_review', 'rejected'].includes(status) ? '-' : value
}

export const schema: Schema = ({
  isListAll,
  insideCampaign,
  campaignAdType
}) => ({
  // /**
  //  * Toggle para gerenciar atividade do anúncios. Apenas advertiser.
  //  */
  // ...(accountType === 'advertiser' ? { ...activeSwitch } : {}),
  /**
   * Dados do anúncio
   */
  title: {
    title: t('rm:Ads'),
    sortKey: 'name',
    render: AdInfo,
    showTotal: true
  },
  /**
   * Status do anúncio e indicação de veiculação
   */
  status: {
    title: t('rm:Status'),
    sortKey: 'ad_status',
    render: Status
  },
  /**
   * Informações da conta vinculada se estiver fora da campanha
   */
  ...(isListAll ? { ...accountInfo } : {}),
  /**
   * Informações da campanha se estiver fora da campanha
   */
  ...(!insideCampaign ? { ...campaignInfo } : {}),
  /**
   * CPC / CPM
   */
  ...cost({ isListAll, campaignAdType }),
  /**
   * Métricas
   */
  // bidStrength: {
  //   title: 'Força do lance',
  //   sortKey: 'BidStrength',
  //   sortInitialToggleDirection: 'DESC',
  //   render: rowData => <BidStrength />
  // },
  impressions: {
    title: t('rm:metrics.impressions'),
    sortKey: 'impressions',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      toggleValueByStatus(rowData.status, rowData.metrics.impressions)
  },
  clicks: {
    title: t('rm:metrics.clicks'),
    sortKey: 'clicks',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      toggleValueByStatus(rowData.status, rowData.metrics.clicks)
  },
  ctr: {
    title: t('rm:metrics.ctr'),
    helpText: t('rm:metrics.ctr.tooltip'),
    sortKey: 'ctr',
    sortInitialToggleDirection: 'DESC',
    render: rowData => toggleValueByStatus(rowData.status, rowData.metrics.ctr)
  },
  totalSpent: {
    title:
      accountType === 'publisher'
        ? t('rm:metrics.totalSpent')
        : t('rm:metrics.cost'),
    sortKey: 'total_spent',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      toggleValueByStatus(rowData.status, rowData?.metrics?.totalSpent)
  },
  conversion: {
    title: t('rm:metrics.conversions'),
    sortKey: 'conversions',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      toggleValueByStatus(rowData.status, rowData.metrics.conversions)
  },
  conversion_rate: {
    title: t('rm:metrics.conversion_rate'),
    sortKey: 'conversion_rate',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      toggleValueByStatus(rowData.status, rowData.metrics.conversionRate)
  },
  total_sales: {
    title: t('rm:metrics.sales'),
    sortKey: 'income',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      toggleValueByStatus(rowData.status, rowData?.metrics?.income)
  },
  total_conversions_items_quantity: {
    title: t('rm:metrics.SoldItems'),
    helpText: t('rm:metrics.SoldItems.tooltip'),
    sortKey: 'total_conversions_items_quantity',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      toggleValueByStatus(
        rowData.status,
        rowData.metrics.totalConversionsItemsQuantity
      )
  },
  roas: {
    title: t('rm:metrics.roas'),
    helpText: t('rm:metrics.roas.tooltip'),
    sortKey: 'roas',
    sortInitialToggleDirection: 'DESC',
    render: rowData => toggleValueByStatus(rowData.status, rowData.metrics.roas)
  },
  adCost: {
    title: t('rm:metrics.adCost'),
    sortKey: 'adcost',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics, status }) => toggleValueByStatus(status, metrics.adcost)
  }
})
