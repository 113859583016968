export const LIST_CHANNELS = '@channels/LIST'

export const CHANNELS_LABEL_MAP = {
  whatsapp: 'WhatsApp',
  whatsapp_bot: 'WhatsApp Bot',
  whatsapp_bot_zs: 'WhatsApp Bot ZS',
  rappi: 'Rappi',
  magento_ecommerce: 'E-commerce',
  vtex_ecommerce: 'E-commerce (Vtex)',
  vtex: 'E-commerce'
}
