import styled from 'styled-components'

export const CardContainer = styled.article`
  --bgColor: white;

  background-color: var(--bgColor);
  width: 100%;

  transition: background-color 0.3s ease-in-out;

  &:not([data-preview='true']) {
    &:hover {
      --bgColor: var(--neutralHighLightest);
    }
  }

  &[data-selected='true'] {
    --bgColor: var(--neutralHighLight);

    aside {
      button {
        &:last-child {
          display: none;
        }
      }
    }

    &:hover {
      aside {
        button {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: inline-flex;
          }
        }
      }
    }
  }
`
