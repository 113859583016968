export const formatSearchString = obj => {
  if (!obj) return ''

  let queryParams = ''
  let count = 0

  Object.keys(obj).forEach(key => {
    const value = obj[key]

    if (value && (value.length > 0 || value > 0)) {
      queryParams += `${count > 0 ? '&' : ''}${key}=${value}`
    }

    count += 1
  })

  return queryParams
}

export const queryDatesDatepicker = (startDate, endDate) => {
  const stringDates = `[
    "${startDate.format('YYYY-MM-DD')}","${endDate.format('YYYY-MM-DD')}"
  ]`
  return {
    'date-range': stringDates
  }
}

export const queryDatesDatepickerIsolated = (startDate, endDate) => {
  return {
    start_date: `${startDate.format('YYYY-MM-DD')}`,
    end_date: `${endDate.format('YYYY-MM-DD')}`
  }
}
