import { useCallback, useMemo, useRef, useState } from 'react'

import { debounce } from 'lodash'

import SelectSearchable from '~/components/SelectSearchable'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { usePublisherTags } from '~/modules/retailMedia/hooks/usePublisherTags'

const queryKey = 'tag_id'

const FilterPublisherTag: React.FC = () => {
  const { t } = useAPPTranslation()
  const [query, setQuery] = useState<string>()

  const executeDebounceFunc = (func: () => void) => func()
  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  const handleQueryTerm = useCallback(
    (term: string | null) => {
      if (term !== '') {
        handleDebounce(() => setQuery(term))
      }
    },
    [handleDebounce]
  )

  const {
    data: response,
    error,
    isFetching,
    isPending
  } = usePublisherTags({
    label: query
  })

  const options: PublisherTagOption[] = useMemo(
    () =>
      (response?.data as PublisherTag[])?.map(item => ({
        value: item.id,
        ...item
      })) || [],
    [response?.data]
  )

  /**
   * <Handle change>
   */
  const queryParams = useQueryParams()
  const tagId = queryParams.get(queryKey) as PublisherTagId

  const selectedTag = useMemo(
    () => options.find(({ id }) => id === tagId) || null,
    [options, tagId]
  )

  const handleChange = useCallback(
    (option: PublisherTagOption) => {
      const value = option?.value

      if (!value) queryParams.delete(queryKey)

      if (value) queryParams.set(queryKey, value)

      history.replace({ search: queryParams.toString() })
    },
    [queryParams]
  )

  return (
    <SelectSearchable
      key={selectedTag?.id}
      name={`${queryKey}-select`}
      placeholder={t('rm:filterByAdvertisersTag')}
      options={options}
      onChange={handleChange}
      isClearable
      defaultValue={selectedTag}
      isSearchable
      onInputChange={handleQueryTerm}
      noOptionsMessage={() => t('c:messages.noResultsFound')}
      size="small"
      // dynamic
      error={error}
      allowCreateWhileLoading
      loading={isPending || isFetching}
      loadingMessage={() => `${t('c:words.loading')}...`}
      onBlur={() => setQuery(null)}
    />
  )
}

export default FilterPublisherTag
