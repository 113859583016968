import { useCallback, useMemo } from 'react'

import SelectSearchable from '~/components/SelectSearchable'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { CAPMPAIGNS_STATUSES as options } from '~/modules/retailMedia/store/common/constants'

const queryKey = 'campaign_status'

const FilterCampaignStatus: React.FC = () => {
  const { t } = useAPPTranslation()

  /**
   * <Handle change>
   */
  const queryParams = useQueryParams()
  const selectedValue = queryParams.get(queryKey) as PublisherTagId

  const selectedTag = useMemo(
    () => options.find(({ value }) => value === selectedValue) || null,
    [selectedValue]
  )

  const handleChange = useCallback(
    (option: Option) => {
      const value = option?.value

      if (!value) queryParams.delete(queryKey)

      if (value) queryParams.set(queryKey, value)

      history.replace({ search: queryParams.toString() })
    },
    [queryParams]
  )

  return (
    <SelectSearchable
      key={selectedTag?.value}
      name="campaign-status-select"
      placeholder={t('rm:filterByCampaignStatus')}
      options={options}
      onChange={handleChange}
      isClearable
      defaultValue={selectedTag}
      isSearchable
      noOptionsMessage={() => t('c:messages.noResultsFound')}
      size="small"
    />
  )
}

export default FilterCampaignStatus
