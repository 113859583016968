import { Form } from '@unform/web'
import { t } from 'i18next'

import { AlertConfirmation } from '~/components/AlertConfirmation'
import { Button } from '~/components/Buttons'
import { FormGroup, Label, Textarea } from '~/components/unform'

import * as S from './styles'

interface Props {
  contentModal: ContentModal
  Modal: React.FC
  showConfirmationModal: boolean
  toggleConfirmationModal(): void
  setIsShowing(): void
  handleSubmit: ({
    dataForm,
    status
  }: {
    dataForm: FormData
    status: CampaignStatus
    action: CampaignActions
  }) => void
}

export const ModalConfirmations = ({
  Modal,
  setIsShowing,
  showConfirmationModal,
  toggleConfirmationModal,
  contentModal,
  handleSubmit
}: Props) => {
  return (
    <Modal
      title={contentModal?.titleModal}
      isOpen={showConfirmationModal}
      onClose={toggleConfirmationModal}
    >
      {!!contentModal?.alertMessage && (
        <S.WrapperAlert>
          <AlertConfirmation
            title={contentModal?.titleAlertMessage}
            description={contentModal?.alertMessage}
          />
        </S.WrapperAlert>
      )}
      <S.Container>
        <Form
          onSubmit={dataForm =>
            handleSubmit({
              ...dataForm,
              status: contentModal?.status,
              action: contentModal?.action
            })
          }
        >
          <FormGroup>
            {!!contentModal?.labelMessage && (
              <Label htmlFor="note" isRequired={contentModal?.noteIsRequired}>
                {contentModal?.labelMessage}
              </Label>
            )}
            {!!contentModal?.subtitletLabelMessage && (
              <small> {contentModal?.subtitletLabelMessage}</small>
            )}
            {!!contentModal?.labelMessage && (
              <Textarea
                name="note"
                minHeight="4px"
                required={contentModal?.noteIsRequired}
              />
            )}
          </FormGroup>

          <S.WrapperButtons hasCancelButton={contentModal?.hasCancelButton}>
            {contentModal?.hasCancelButton && (
              <Button template="transparent" onClick={toggleConfirmationModal}>
                Cancelar
              </Button>
            )}
            <Button
              template={contentModal?.templateButton || 'primary'}
              customWidth={!contentModal?.hasCancelButton ? '100%' : '0'}
              type="submit"
            >
              {contentModal?.textButton || t('rm:Continue')}
            </Button>
          </S.WrapperButtons>
        </Form>
      </S.Container>
    </Modal>
  )
}
