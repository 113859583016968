// https://api-retail-media.newtail.com.br/ad/actions/review/reject/:ad_id
// https://api-retail-media.newtail.com.br/ad/actions/review/approve/:ad_id

import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../../../api'

const baseURL = `${baseAPI}/ad/actions/review`

type ApproveAdService = {
  adId: string
}

export const approveAdService = ({ adId }: ApproveAdService) =>
  axios.patch<ResponseGetAdSize>(`${baseURL}/approve/${adId}`)

type RejectAdService = {
  adId: string
  body: {
    note: string
  }
}

export const rejectAdService = ({ adId, body }: RejectAdService) =>
  axios.patch(`${baseURL}/reject/${adId}`, body)
