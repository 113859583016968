import { useCallback, useState } from 'react'

import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import { FormGroup, Input, Label } from '~/components/unform'
import { CheckboxList } from '~/components/unform/CheckboxList'
import { isReadOnly } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

type FormEditLinkProps = {
  handleCancel?: () => void
  options: CheckboxOptionProps[]
  initialData: {
    seller_id?: string
    type?: {
      [key: string]: boolean
    }
    brands: string[]
  }
  onSubmit: (data: unknown) => void
  fields: AppInfoLinkConfig[]
}

type Brand = { id: string; name: string }

const defaultFields = ['adTypes', 'sellerId', 'brands']

const FormEditLink: React.FC<FormEditLinkProps> = ({
  handleCancel,
  initialData,
  options,
  onSubmit,
  fields = defaultFields
}) => {
  const { t } = useAPPTranslation()

  /**
   * Handle brands
   */

  const [values, setValues] = useState<Brand[]>(
    initialData?.brands?.map((item, index) => ({
      id: item + index,
      name: item
    })) || []
  )

  type HandleChange = { data: OnChangeInputData; id: string }

  const handleChange = ({ data: { value }, id }: HandleChange) => {
    const handleAction = (brands: { id: string; name: string }[]) => {
      const newData = brands?.map(item =>
        item.id !== id ? item : { ...item, name: value }
      )

      return newData
    }

    setValues(handleAction)
  }

  const handleDeleteBrands = (value: Brand) => {
    const handleAction = (brands: Brand[]) => {
      const newData = brands?.filter(item => item.id !== value.id)

      return newData
    }

    setValues(handleAction)
  }

  const handleAddBrands = useCallback(() => {
    setValues(prevState => {
      if (!prevState) return [{ id: 'new0', name: '' }]

      const length = prevState.length
      const id = `new${length}`
      const name = ''
      return [...prevState, { id, name }]
    })
  }, [])

  return (
    <Form onSubmit={onSubmit} initialData={initialData}>
      {fields.includes('adTypes') && (
        <FormGroup>
          <Label text={t('rm:formEditLink.form.type')} />
          <CheckboxList options={options} name="type" />
        </FormGroup>
      )}

      {fields.includes('sellerId') && (
        <FormGroup className="mt-4">
          <Label text={t('rm:formEditLink.form.sellerId')} />
          <Input name="seller_id" disabled={isReadOnly()} />
        </FormGroup>
      )}

      {fields.includes('brands') && (
        <FormGroup className="mt-4">
          <Label
            text={t('rm:formEditLink.form.brands.label')}
            helperText={t('rm:formEditLink.form.brands.helperText')}
          />

          {values?.map((value, index) => (
            <div className="d-flex mb-1 gap-1" key={value.id}>
              <Input
                name={`brands[${index}]`}
                defaultValue={value.name}
                onChange={data => handleChange({ data, id: value.id })}
                disabled={isReadOnly()}
              />

              {!isReadOnly() && (
                <Button
                  text={t('c:actions.remove')}
                  onClick={() => handleDeleteBrands(value)}
                  template="deleteOutline"
                  size="small"
                  customWidth="auto"
                />
              )}
            </div>
          ))}

          <Button
            text={t('rm:formEditLink.form.brands.addButton')}
            size="xsmall"
            template="primaryOutlined"
            customWidth="auto"
            iconLeft="+"
            onClick={handleAddBrands}
          />
        </FormGroup>
      )}

      {!isReadOnly() && (
        <FormGroup className="actionButtons">
          {handleCancel && (
            <Button
              text={t('common:actions.cancel')}
              onClick={handleCancel}
              template="transparentDanger"
              size="small"
            />
          )}

          <Button
            type="submit"
            text={t('common:actions.save')}
            textLoading={t('common:actions.saving')}
            size="small"
            template="success"
          />
        </FormGroup>
      )}
    </Form>
  )
}

export default FormEditLink
