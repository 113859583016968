// React import is required because the react-selct is using React 16, which requires it for JSX.
import React from 'react'
import { FiCheck, FiPlus, FiX } from 'react-icons/fi'
import { LiaExchangeAltSolid } from 'react-icons/lia'

import i18n from '~/i18n'
export const buttons: { [key: string]: Partial<ButtonProps> } = {
  default: {
    text: i18n.t('rm:AudienceCard.buttons.default.label'),
    template: 'primaryOutlined',
    iconLeft: <FiPlus />
  },
  selected: {
    text: i18n.t('rm:AudienceCard.buttons.selected.label'),
    template: 'success',
    iconLeft: <FiCheck />
  },
  changeMode: {
    text: i18n.t('rm:AudienceCard.buttons.changeMode.label'),
    template: 'primaryOutlined',
    iconLeft: <LiaExchangeAltSolid />
  },
  remove: {
    text: i18n.t('rm:AudienceCard.buttons.remove.label'),
    template: 'deleteOutline',
    iconLeft: <FiX />
  }
}
