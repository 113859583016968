import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { isEqual } from 'lodash'

import {
  requestRetailMediaAccountID,
  formatSearchString,
  queryDatesDatepickerIsolated,
  getWhiteLabelPublisherId,
  getRetailMediaAccountType
} from '~/helpers'
import handleQueryParams from '~/helpers/handleQueryParams'
import { handleSortDirection } from '~/helpers/sortData'
import { useAppInfo } from '~/hooks/useAppInfo'
import useFetchSWR from '~/hooks/useFetchSWR'
import useQueryParams from '~/hooks/useQuery'
import { formatAds } from '~/modules/retailMedia/dtos/common/ads'
import {
  approveAdService,
  rejectAdService
} from '~/modules/retailMedia/services/ad/action/review'
import { listAdsResultsV2Service } from '~/modules/retailMedia/services/ad/result/v2'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import { useAppSelector } from '~/store/hooks'

import { schema } from '../schemas'

const MediaRejectedTableContext = createContext<MediaRejectedTableContextData>(
  {} as MediaRejectedTableContextData
)

/**
 * Chaves usadas nas query params que alteram a
 * quantidade resultados e impactam na paginação
 */
const dynamicKeys: (keyof QueryParamsAds)[] = [
  'campaign_id',
  'publisher_id',
  'advertiser_id',
  'campaign_name',
  'product_sku',
  'account_info',
  'ad_status',
  'ad_type',
  'end_date',
  'quantity',
  'show_inactive',
  'start_date'
]

const MediaRejectedTableProvider = ({
  children,
  campaignAdType = null
}: MediaRejectedTableProviderProps) => {
  const { id: campaignId } = useParams()

  const [queryParams, setQueryParams] = useState({} as QueryParamsAds)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)

  /**
   * Handle request
   */

  const params = useMemo(() => {
    const data = {
      campaign_id: campaignId,
      page,
      quantity: perPage,
      ad_type: 'banner',
      ad_status: 'rejected',
      account_info: false
    } as QueryParamsAds

    return data
  }, [campaignId, page, perPage])

  useLayoutEffect(() => {
    if (isEqual(params, queryParams)) {
      return
    }

    const { shouldResetFixedKeys } = handleQueryParams({
      params,
      currentParams: queryParams,
      dynamicKeys
    })

    if (shouldResetFixedKeys) {
      params.page = 1
      setPage(1)
    }

    setQueryParams(params)
  }, [params, queryParams])

  const queryKey = ['get-ads', 'pending_review', campaignId, page, perPage]

  const { data, isLoading, isFetching, error } = useQuery({
    enabled: !!queryParams.campaign_id,
    queryKey,
    refetchInterval: 60000,
    queryFn: async () => {
      const response = await listAdsResultsV2Service({ params })

      return response?.data
        ? { ...response.data, data: formatAds({ ads: response.data.data }) }
        : null
    }
  })

  /**
   * Handle list
   */

  const handlePagination = useCallback((page: number) => {
    setPage(page)
  }, [])

  const handleItemsPerPageChange = useCallback(
    ({ value }: { value: number }) => {
      setPerPage(value)
    },
    []
  )

  return (
    <MediaRejectedTableContext.Provider
      value={{
        data: data?.data,
        error,
        isLoading,
        isFetching,
        page,
        perPage,
        total: data?.total || 0,
        schema: schema(),
        handlePagination,
        handleItemsPerPageChange
      }}
    >
      {children}
    </MediaRejectedTableContext.Provider>
  )
}

function useMediaRejectedTable(): MediaRejectedTableContextData {
  const context = useContext(MediaRejectedTableContext)

  if (!context) {
    throw new Error(
      'useMediaRejectedTable must be used within an MediaRejectedTableProvider'
    )
  }

  return context
}

export {
  MediaRejectedTableContext,
  MediaRejectedTableProvider,
  useMediaRejectedTable
}
