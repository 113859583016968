import { useMemo } from 'react'

import useQueryParams from '../useQuery'

const useAppSearchParams = <T extends Record<keyof T, ParamConfig<unknown>>>(
  queryKeyParams: QueryParamWithDefault<T>[]
) => {
  const searchParams = useQueryParams()

  const params = useMemo(() => {
    return queryKeyParams.reduce(
      (acc, { key, defaultValue = null }) => {
        const value = searchParams.get(String(key))

        acc[key] =
          value !== null ? (value as T[typeof key]['type']) : defaultValue
        return acc
      },
      {} as { [K in keyof T]: T[K]['defaultValue'] }
    )
  }, [queryKeyParams, searchParams])

  return params
}

export default useAppSearchParams
