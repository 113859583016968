import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { isEqual } from 'lodash'

import {
  requestRetailMediaAccountID,
  formatSearchString,
  queryDatesDatepickerIsolated,
  getWhiteLabelPublisherId,
  history,
  isAdvertiser
} from '~/helpers'
import handleQueryParams from '~/helpers/handleQueryParams'
import { handleSortDirection } from '~/helpers/sortData'
import { useAppInfo } from '~/hooks/useAppInfo'
import useAppSearchParams from '~/hooks/useAppSearchParams'
import useQueryParams from '~/hooks/useQuery'
import { formatAds } from '~/modules/retailMedia/dtos/common/ads'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import { useAppSelector } from '~/store/hooks'

import { adListqueryKeyParams } from '../filter/keys'
import { schema } from '../schemas'

const AdsTableContext = createContext<AdsTableContextData>(
  {} as AdsTableContextData
)

/**
 * Chaves usadas nas query params que alteram a
 * quantidade resultados e impactam na paginação
 */
const dynamicKeys: (keyof QueryParamsAds)[] = [
  'campaign_id',
  'publisher_id',
  'advertiser_id',
  'campaign_name',
  'product_sku',
  'account_info',
  'ad_status',
  'ad_type',
  'end_date',
  'quantity',
  'show_inactive',
  'start_date',
  'targeting_type',
  'tag_id'
]

const AdsTableProvider = ({
  children,
  campaignAdType = null
}: AdsTableProviderProps) => {
  const { id: campaignId } = useParams()

  const insideCampaign = !!campaignAdType || campaignId

  const searchParams = useQueryParams()

  const {
    rmid,
    product_sku,
    ad_status,
    ad_type,
    campaign_name,
    hide_inactive,
    campaign_targeting: targeting_type,
    tag_id,
    order_direction,
    order_by,
    quantity,
    page
  } = useAppSearchParams<AdListSearchParams>(adListqueryKeyParams)

  const { isWhiteLabel } = useAppInfo()

  const shouldUseTokenId = useMemo(
    () => !insideCampaign && isWhiteLabel && isAdvertiser,
    [insideCampaign, isWhiteLabel]
  )

  const paramId = useMemo(
    () => (insideCampaign ? campaignId : rmid),
    [campaignId, insideCampaign, rmid]
  )

  const id = useMemo(
    () => (shouldUseTokenId ? getWhiteLabelPublisherId() : paramId),
    [paramId, shouldUseTokenId]
  )
  // Checa se tem id do publisher ou advertiser para saber se é um listagem geral.
  // Isso causa alterações no schema da tabela
  const isListAll = !id && !campaignAdType && !isWhiteLabel

  const [innerLoading, setInnerLoading] = useState(false)
  const [response, setResponse] = useState<AdResultsResponse>()
  const [queryParams, setQueryParams] = useState<QueryParamsAds>(null)

  /**
   * Redux
   */

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  /**
   * Handle request
   */

  const params = useMemo(() => {
    const filterKey = insideCampaign
      ? 'campaign_id'
      : requestRetailMediaAccountID

    const data = {
      [filterKey]: id,
      ...queryDatesDatepickerIsolated(startDate, endDate),
      show_inactive: `${!hide_inactive}`,
      targeting_type,
      page,
      quantity,
      ad_type: insideCampaign ? null : ad_type,
      ad_status,
      campaign_name: insideCampaign ? null : campaign_name,
      tag_id,
      // Ainda não temos suporte para 2 queries no <Filters />
      product_sku:
        insideCampaign && campaignAdType !== 'product'
          ? null
          : product_sku || null,
      account_info: isListAll,
      order_direction,
      order_by,
      hide_pending_rejected: true
    } as QueryParamsAds

    return data
  }, [
    ad_status,
    ad_type,
    campaignAdType,
    campaign_name,
    endDate,
    hide_inactive,
    id,
    insideCampaign,
    isListAll,
    order_by,
    order_direction,
    page,
    product_sku,
    quantity,
    startDate,
    tag_id,
    targeting_type
  ])

  const queryKey = useMemo(
    () => ['get-ads', paramId, JSON.stringify(queryParams)],
    [paramId, queryParams]
  )

  const {
    data: queryData,
    isLoading,
    isFetching,
    error
  } = useQuery({
    enabled: !insideCampaign || !!queryParams?.campaign_id,
    queryKey,
    refetchInterval: 60000,
    queryFn: async () => {
      const response = await axios.get(`${reatailmediaApi}/ad/results/v2`, {
        params: queryParams
      })

      return response.data
    }
  })

  useEffect(() => {
    if (queryData) {
      setResponse(queryData)
    }
  }, [queryData])

  //

  const { data, currentPage, pages, total }: AdsDataMemo = useMemo(() => {
    const formattedData = response?.data
      ? formatAds({ ads: response.data })
      : null

    setInnerLoading(false)
    return {
      currentPage: response?.currentPage,
      pages: response?.pages,
      total: response?.total,
      data: formattedData
    }
  }, [response])

  /**
   * HANDLE UPDATE ADS AFTER
   */

  const queryClient = useQueryClient()

  const handleMutate = useCallback(
    async (data: AdsHandleMutateProps) => {
      queryClient.invalidateQueries({
        queryKey
      })
    },
    [queryClient, queryKey]
  )

  /**
   * Handle list
   */

  const handleActive = useCallback(
    ({ e }: HandleActiveProps) => {
      const value = e.target.checked

      searchParams.set('hide_inactive', String(value))
      searchParams.set('page', '1')
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )

  /**
   * Handle Pagination
   */
  const handlePagination = useCallback(
    (value: number) => {
      searchParams.set('page', String(value))
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      searchParams.set('quantity', String(value.value))
      searchParams.set('page', '1')
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )
  /**
   * Sort list
   */

  const handleSortList = useCallback(
    (data: OnSortProps) => {
      const selectedKey = data.key
      const sortInitialDirection = data?.sortInitialDirection

      const response = handleSortDirection({
        selectedKey,
        currentSortDirection: order_direction,
        currentSortKey: order_by,
        sortInitialDirection
      })

      searchParams.set('order_direction', response.sortDirection)
      searchParams.set('order_by', response.sortKey)

      history.push({ search: searchParams.toString() })
    },
    [order_by, order_direction, searchParams]
  )

  /**
   * Handle coleteral effect
   */
  useLayoutEffect(() => {
    if (isEqual(params, queryParams)) {
      return
    }

    const { shouldResetFixedKeys } = handleQueryParams({
      params,
      currentParams: queryParams,
      dynamicKeys
    })

    if (shouldResetFixedKeys) {
      params.page = 1
      handlePagination(1)
    }

    setInnerLoading(true)
    setQueryParams(params)
  }, [handlePagination, params, queryParams])

  return (
    <AdsTableContext.Provider
      value={{
        data,
        error,
        innerLoading,
        isValidating: isFetching,
        currentPage,
        insideCampaign,
        pages,
        page,
        perPage: quantity,
        total,
        schema: schema({
          isListAll,
          insideCampaign,
          campaignAdType
        }),
        handleActive,
        handlePagination,
        handleItemsPerPageChange,
        sortDirection: order_direction,
        sortKey: order_by,
        handleSortList,
        handleMutate,
        queryParams: formatSearchString(queryParams),
        hideInactive: hide_inactive
      }}
    >
      {children}
    </AdsTableContext.Provider>
  )
}

function useAdsTable(): AdsTableContextData {
  const context = useContext(AdsTableContext)

  if (!context) {
    throw new Error('useAdsTable must be used within an AdsTableProvider')
  }

  return context
}

export { AdsTableContext, AdsTableProvider, useAdsTable }
