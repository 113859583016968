export const getValidColor = (color: string): string | null => {
  return isHexColor(color) ? color : null
}

export function isHexColor(color: string) {
  const regex = /^#?([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/

  const isValid = regex.test(color)

  if (!isValid) {
    console.warn('Invalid color', color)
  }
  return isValid
}
