import localstorageConstants, {
  getLocalstorage
} from './localstorage-manipulation'

type GetAccountInfoStorage = () => {
  name: string
  logo: string
  bgLogo: string
  host: string
  checkoutLink: string
  sellerId?: string
}

export const getAccountInfo: GetAccountInfoStorage = () => {
  const data = JSON.parse(getLocalstorage(localstorageConstants.ACCOUNT_INFO))

  return data
}
