export const campaignListqueryKeyParams: QueryParamWithDefault<CampaignListSearchParams>[] =
  [
    { key: 'rmid' },
    { key: 'tag_id' },
    { key: 'campaign_name' },
    { key: 'campaign_status' },
    { key: 'ad_type' },
    { key: 'campaign_targeting' },
    { key: 'order_direction' },
    { key: 'order_by' },
    { key: 'quantity', defaultValue: 10 },
    { key: 'page', defaultValue: 1 }
  ]
